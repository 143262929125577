import { IUser } from 'models'

const load = (id: string, fallback?: any): any => {
  try {
    const json = window.localStorage.getItem(id) ?? JSON.stringify(fallback)
    const response = JSON.parse(json)
    return response
  } catch {
    return fallback
  }
}

export const saveUser = (user: Partial<IUser>): void => {
  const json = JSON.stringify(user)
  window.localStorage.setItem('CURRENT-USER', json)
}

export const loadUser = (): Partial<IUser> => load('CURRENT-USER', null)
export const clearUser = (): void =>
  window.localStorage.removeItem('CURRENT-USER')

// Password

export const savePassword = (id: number, password: string): void => {
  const json = JSON.stringify(password)
  window.localStorage.setItem(`PASSWORD-${id}`, json)
}

export const loadPassword = (id: number): string => load(`PASSWORD-${id}`, '')
