import Rollbar from 'rollbar'
import * as globals from './globals'

export const transform = (payload, context) => {
  if (context.err?.details) {
    Object.assign(payload, { details: context.err.details })
  }
}

const rollbar = new Rollbar({
  accessToken: globals.rollbar,
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: globals.stage === 'production',
  environment: globals.stage,
  transform
})

export default rollbar
