import React, { createContext, useState } from 'react'
import { getPosts, getPublishedScheduleItems } from 'api'
import { IEvent, IPost, IScheduleItem, IUser } from 'models'
import { saveUser, loadUser } from '../localStorage'
import Debug from 'debug'

const log = Debug('gallery').extend('context').extend('app')

export interface IAppContext {
  event: IEvent
  scheduleItems?: IScheduleItem[]
  posts?: IPost[]
  user?: IUser
  loadEvent: (locator: string) => Promise<void>
  loadPosts: (scheduleItemId: number) => Promise<void>
  updateUser: (data: IUser) => void
}

const defaultContext: IAppContext = {}

export const AppContext = createContext<IAppContext>(defaultContext)
AppContext.displayName = 'App Context'

interface AppProviderProps extends Partial<IAppContext> {
  children: JSX.Element[] | JSX.Element
}
export const AppProvider = (props: AppProviderProps): JSX.Element => {
  log({ func: 'AppProvider', props })
  const [user, setUser] = useState<IUser | undefined>(props.user ?? loadUser())
  const [event, setEvent] = useState<IEvent | undefined>(props.event)
  const [scheduleItems, setScheduleItems] = useState<IScheduleItem[] | undefined>(props.scheduleItems)
  const [posts, setPosts] = useState<IPost[] | undefined>(props.posts)

  const loadEvent = async (locator: string): Promise<void> => {
    const lLog = log.extend('loadEvent')
    lLog({ locator })
    const { event, scheduleItems } = await getPublishedScheduleItems(locator)
    setEvent(event)
    lLog({ event, scheduleItems })
    const items = scheduleItems.sort((a: IScheduleItem, b: IScheduleItem) =>
      (a.startsAt ?? '').localeCompare(b.startsAt ?? '')
    )
    setScheduleItems(items ?? [])
  }

  const loadPosts = async (itemId: number): Promise<void> => {
    if (event === undefined) return
    const result = await getPosts(event.locator ?? '', itemId)
    setPosts(result)
  }

  const updateUser = (data: IUser): void => {
    setUser(data)
    saveUser(data)
  }

  return (
    <AppContext.Provider
      value={{
        event: { ...(event as IEvent), scheduleItems },
        scheduleItems,
        posts,
        user,
        loadEvent,
        loadPosts,
        updateUser
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}
