import React, {
  ChangeEventHandler,
  FormEventHandler,
  ReactNode,
  SyntheticEvent,
  useContext,
  useState
} from 'react'
import classNames from 'classnames'
import { AppContext, IAppContext } from 'context'
import { setCookie, loadCookie } from 'libs/cookies'
import Field from 'components/core/Field'
import MarketingCheckbox from 'components/core/MarketingCheckbox'
import Modal from 'components/core/Modal'
import './style.scss'
import { IUser } from 'models'
import debug from 'debug'

const log = debug('EmailGate')

function linkFromEmail (): boolean {
  // URLSearchParams is not defined in Edge < 17
  if (typeof window.URLSearchParams !== 'function') return false

  const params = new URLSearchParams(window.location.search)
  const medium = params.get('utm_medium') ?? ''
  return medium.toLowerCase() === 'email'
}

interface EmailGateProps {
  fan?: IUser
}
export default function EmailGate (props: Readonly<EmailGateProps>): ReactNode {
  const { event = {}, user } = useContext<IAppContext>(AppContext)
  const {
    locator = '',
    askEmail = false,
    termsOfServiceUrl = 'https://stagepilot.com/legal/terms-of-use',
    privacyPolicyUrl = 'https://stagepilot.com/legal/privacy'
  } = event
  const hasLocator = locator !== ''
  const { email = '' } = user ?? {}
  const defaultProps = {
    fan: { firstName: '', lastName: '', email: '' },
    ...props
  }
  const [cookieValue, setCookieValue] = useState<string>(
    loadCookie(`${locator}_email_gate_seen`)
  )
  const [success, setSuccess] = useState<boolean>(false)
  const [fan, setFan] = useState<IUser>(defaultProps.fan)

  const showing = (): boolean => {
    const visible = askEmail && (email === '' || success) && cookieValue.length <= 0

    if (visible) return !linkFromEmail()

    return false
  }

  const handleChange: ChangeEventHandler = ({
    target
  }: React.ChangeEvent<HTMLFormElement>) => {
    setFan({
      ...fan,
      [target.name]: target.value
    })
  }

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    log(locator, fan)
    setSuccess(true)
    dismiss()
  }

  const handleCancel = (e: SyntheticEvent): void => {
    e.preventDefault()
    dismiss()
  }

  const dismiss = (): void => {
    gateSeen()
    setCookieValue(hasLocator ? locator : Date.now.toString())
  }

  const gateSeen = (): void => {
    setCookie({
      name: `${locator}_email_gate_seen`,
      value: locator,
      days: 2
    })
  }

  if (!showing()) {
    return null
  }

  const classes = classNames('EmailGate', 'track-email-gate', {
    'EmailGate--success': success
  })

  const text = {
    pitch: (
      <>
        Be first to know about
        <br /> discounts and promotions!
      </>
    ),
    reward: false,
    caption: 'Continue'
  }

  return (
    <div className={classes} data-testid='EmailGate'>
      <Modal handleClose={handleCancel} show>
        {success
          ? (
            <div className='EmailGate__Modal'>
              <p className='EmailGate__Subtitle'>SUCCESS!</p>
              <h1 className='EmailGate__Title'>{text.reward}</h1>

              <button className='primary' onClick={handleCancel}>
                Got it!
              </button>
            </div>
            )
          : (
            <div className='EmailGate__Modal'>
              <h1 className='EmailGate__Title'>{text.pitch}</h1>

              <form onSubmit={handleSubmit}>
                <div className='EmailGate__Field'>
                  <Field
                    required
                    name='firstName'
                    placeholder='First name'
                    value={fan?.firstName}
                    onChange={handleChange}
                  />
                  <Field
                    required
                    name='lastName'
                    placeholder='Last name'
                    value={fan?.lastName}
                    onChange={handleChange}
                  />
                </div>

                <Field
                  type='email'
                  required
                  name='email'
                  className='EmailGate__Field'
                  placeholder='Email address'
                  pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
                  value={fan?.email}
                  onChange={handleChange}
                />

                <div className='EmailGate__Field--marketing'>
                  <MarketingCheckbox />
                </div>

                <div className='EmailGate__Buttons'>
                  <button type='submit' className='Button Button--primary'>
                    {text.caption}
                  </button>
                  <button
                    className='track-decline-email-gate Button Button--tertiary'
                    onClick={handleCancel}
                  >
                    No, thanks
                  </button>
                </div>
              </form>

              <p className='EmailGate__Legal'>
                By continuing, you agree to our{' '}
                <a
                  href={termsOfServiceUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >Terms of Use
                </a>{' '}
                and{' '}
                <a
                  href={privacyPolicyUrl}
                  target='_blank'
                  rel='noopener noreferrer'
                >Privacy Policy
                </a>{/*
                */}.
              </p>
            </div>
            )}
      </Modal>
    </div>
  )
}
