import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import './style.scss'

const DEFAULT_WINDOW_SIZE = 8

interface GalleryGridProps {
  className?: string
  items?: any[]
  itemParams?: any
  windowSize?: number
  Component: any
}
export default function GalleryGrid ({
  className,
  items = [],
  itemParams = {},
  windowSize = DEFAULT_WINDOW_SIZE,
  Component
}: Readonly<GalleryGridProps>): JSX.Element {
  const rowRef = useRef<HTMLDivElement>(null)
  const [rowHeight, setRowHeight] = useState<number>(0)
  const [scrolledBy, setScrolledBy] = useState<number>(0)
  const [animationFrameRequest, setAnimationFrameRequest] = useState<any>()

  useEffect((): any => {
    updateScrollPosition()

    return (): void => {
      cancelAnimationFrame(animationFrameRequest)
    }
  }, [])

  const columns = (): number => {
    const width = window.innerWidth ?? 300
    if (width < 425) return 1
    if (width < 600) return 2
    return 3
  }

  const measureRowHeight = (): number => {
    if (rowRef.current != null) {
      if (rowRef.current.children.length > 0) {
        const first = rowRef.current.children[0] as HTMLElement
        const padding = first.offsetLeft - rowRef.current.offsetLeft
        return first.offsetHeight + padding
      }
    }
    return 0
  }

  const maxScroll = (): number => {
    return Math.max(
      0,
      Math.round(items.length / columns()) -
        (windowSize ?? DEFAULT_WINDOW_SIZE)
    )
  }

  const updateScrollPosition = (): void => {
    const height = measureRowHeight()

    if (height > 0) {
      const snapped = Math.floor(Math.max(0, window.scrollY - height) / height)
      setRowHeight(height)
      setScrolledBy(Math.min(snapped, maxScroll()))
    }

    setAnimationFrameRequest(requestAnimationFrame(updateScrollPosition))
  }

  const renderPage = (page: any, Component: any, props: any): JSX.Element[] => {
    const cols = columns()
    const first = Math.max(page * cols, 0)
    const last = first + windowSize * cols

    let rows: any[] = []
    for (let i = first; i <= last && i < items.length; i += cols) {
      const key = items[i].id

      const rowItems = items.slice(i, i + cols)

      rows = rows.concat(
        <React.Fragment key={key}>
          {rowItems.map((i) => (
            <Component key={i.id} item={i} {...props} />
          ))}
        </React.Fragment>
      )
    }

    return rows
  }

  const currentPage = renderPage(scrolledBy, Component, itemParams)
  const marginTop = scrolledBy * rowHeight
  const marginBottom = (maxScroll() - scrolledBy) * rowHeight

  return (
    <div
      className={classNames('GalleryGrid', className)}
      style={{ marginTop, marginBottom }}
      ref={rowRef}
    >
      {currentPage}
    </div>
  )
}
