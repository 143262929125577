import React, { InputHTMLAttributes, ReactElement } from 'react'
import classNames from 'classnames'
import './style.scss'

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean
  enabled?: boolean
}

const Field = ({
  error = false,
  required = false,
  enabled = true,
  type = 'text',
  className = '',
  ...props
}: Props): ReactElement => {
  const classnames = classNames(className, 'Field', {
    'Field--required': required,
    'Field--error': error
  })
  return (
    <input
      type={type}
      required={required}
      className={classnames}
      disabled={!enabled}
      {...props}
    />
  )
}

export default Field
