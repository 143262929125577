import React, { LinkHTMLAttributes } from 'react'

const Preload = ({
  href = '',
  rel = 'prefetch',
  as = 'image'
}: LinkHTMLAttributes<HTMLLinkElement>): JSX.Element | null => {
  return href === '' ? null : <link href={href} rel={rel} as={as} />
}

export default Preload
