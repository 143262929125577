interface CookieData {
  name: string
  value: string
  days: number
}
export const setCookie = ({ name, value, days }: CookieData): void => {
  let expires = ''

  if (days !== undefined) {
    const date = new Date()
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
    expires = '; expires=' + date.toUTCString()
  }

  document.cookie =
    encodeURIComponent(name) +
    '=' +
    encodeURIComponent(value) +
    expires +
    '; path=/'
}

export const loadCookie = (name: string, fallback: string = ''): any => {
  const cookie =
    document.cookie
      .split(';')
      .find((item) => item.includes(`${encodeURIComponent(name)}=`)) ?? ''

  const value = cookie.split('=').slice(1).join('=')

  return value ?? fallback
}
