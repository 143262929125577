import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import './style.scss'

export interface ImageProps {
  alt?: string
  className: string
  previewSrc?: string
  src: string
  onLoad: (e: any) => void
}

export default function Image ({
  className,
  previewSrc = '',
  src,
  onLoad,
  ...props
}: Readonly<ImageProps>): JSX.Element {
  const [loading, setLoading] = useState<boolean>(false)
  const [loaded, setLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (src !== undefined) showLoading()
  }, [src])

  const imageLoaded = (e: any): void => {
    setLoaded(true)
    setLoading(false)

    onLoad(e)
  }

  const showLoading = (): void => {
    setLoaded(false)
    setLoading(true)
  }

  let usingPreview = false
  let imgSrc: string

  if (!loaded && previewSrc !== '') {
    imgSrc = previewSrc
    usingPreview = true
  } else {
    imgSrc = src
  }

  const classnames = classNames('Image', className, {
    'Image--loading': loading,
    'Image--preview': usingPreview
  })

  return (
    <div className={classnames}>
      <img
        className='Image__Img'
        src={imgSrc}
        {...props}
        onLoad={imageLoaded}
      />
    </div>
  )
}
