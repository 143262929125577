import React, { ReactNode, useContext } from 'react'
import { AppContext, IAppContext } from 'context'
import Field from 'components/core/Field'
import './style.scss'

export default function MarketingCheckbox (): ReactNode | null {
  const { user } = useContext<IAppContext>(AppContext)
  let { registered = false, marketingConsent = false } = user ?? {}

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (user != null) user.marketingConsent = marketingConsent = !!event.target.checked
  }

  if (registered && marketingConsent) return null

  return (
    <div className='MarketingCheckbox'>
      <Field
        type='checkbox'
        name='marketing'
        id='marketing'
        checked={marketingConsent}
        onChange={handleChange}
      />
      <label htmlFor='marketing'>
        Be the first to know about upcoming promotions.
      </label>
    </div>
  )
}
