declare const GIT_REV: string
declare const ROLLBAR: string
declare const STAGE: string

const vipApiHosts = {
  dev: 'https://api.beta.next.stagepilot.com',
  qa: 'https://api.beta.qa.stagepilot.com',
  prod: 'https://api.beta.stagepilot.com'
}

const vipImageHosts = {
  dev: 'https://images.beta.next.stagepilot.com',
  qa: 'https://images.beta.qa.stagepilot.com',
  prod: 'https://images.beta.stagepilot.com'
}

export const stage = STAGE
export const apiHost: string = vipApiHosts[stage] ?? 'http://localhost:5000/api'
export const gitRev = GIT_REV
export const imageHost: string = vipImageHosts[stage] ?? 'http://localhost:8080/images'
export const rollbar = ROLLBAR
