import React, { ReactNode, useState } from 'react'

export interface IModalContext {
  open: boolean
  to: string | undefined
  id: number
  showPasswordModal?: (show: boolean, props) => void
}

export interface ModalProviderProps extends Partial<IModalContext> {
  children: ReactNode | null
}

export const ModalContext = React.createContext<IModalContext>({
  open: false,
  to: undefined,
  id: 0
})
ModalContext.displayName = 'Modal Context'

export function ModalProvider ({
  id = 0,
  to,
  open = false,
  children
}: Readonly<ModalProviderProps>): ReactNode {
  const [data, setData] = useState<Partial<IModalContext>>({
    id,
    to,
    open
  })

  const showPasswordModal = (show: boolean, props: any): void => {
    setData({ ...data, ...props, open: show })
  }

  return (
    <ModalContext.Provider
      value={{
        ...data,
        showPasswordModal
      }}
    >
      {children}
    </ModalContext.Provider>
  )
}
