import React, {
  ReactElement,
  ReactEventHandler,
  useContext,
  useEffect,
  useState
} from 'react'
import { track } from 'libs/analytics'
import { Link, useNavigate, useParams } from 'react-router-dom'
import GalleryGrid from 'components/core/GalleryGrid'
import Page from 'components/core/Page'
import Thumbnail from 'components/core/Thumbnail'
import EmailGate from 'components/EmailGate'
import Header from 'components/Header'
import RegisterFan from 'components/RegisterFan'
import { IPost, IScheduleItem } from 'models'
import { trackTimeSincePageLoadStart } from 'components/PerformanceTracker'
import './style.scss'
import { AppContext, IAppContext } from 'context'
import { loadPassword } from 'localStorage'

const WINDOW_SIZE = 8

interface PostItemProps {
  item: IPost
  onLoad: ReactEventHandler<HTMLImageElement>
}

export function PostItem ({ item: { id = 0, scheduleItemId = 0, contentDigest = '', isAnimated = false }, onLoad }: PostItemProps): ReactElement {
  const { locator = '' } = useParams()

  return (
    <Link
      to={`/${locator}/${scheduleItemId}/${id}`}
      className='PostList__Item'
    >
      <Thumbnail
        className='PostItem__Thumbnail'
        digest={contentDigest}
        playable={isAnimated}
        alt={`Photo ${id}`}
        onLoad={onLoad}
      />
    </Link>
  )
}

export default function PostList (): ReactElement {
  const { event, scheduleItems, posts = [], loadEvent, loadPosts } = useContext<IAppContext>(AppContext)
  const navigate = useNavigate()
  const { locator = '', scheduleItemId = ' 0' } = useParams()
  const [scheduleItem, setScheduleItem] = useState<IScheduleItem | undefined>()
  const [loadedImages, setLoadedImages] = useState(0)
  const [galleryLoaded, setGalleryLoaded] = useState(false)

  useEffect(() => {
    if (locator !== '' && locator !== event?.locator) void loadEvent(locator)
  }, [locator])

  useEffect(() => {
    if (scheduleItems === undefined) return

    const item = scheduleItems?.find(
      (si) => si.id === parseInt(scheduleItemId ?? '0')
    )

    if (item === undefined) return

    const { id, hasPassword = false } = item
    if (hasPassword && loadPassword(id) === '') navigate(`/${locator}`)

    if (item !== scheduleItem) {
      setScheduleItem(item)
      void loadPosts(item.id)
    }
  }, [scheduleItems])

  useEffect(() => {
    track('Viewed Gallery', {
      event: locator,
      galleryId: scheduleItemId
    })
  }, [scheduleItemId])

  useEffect(() => {
    const imagesToLoad = Math.min(posts.length, WINDOW_SIZE)

    if (!galleryLoaded && imagesToLoad > 0 && loadedImages >= imagesToLoad) {
      setGalleryLoaded(true)
      trackTimeSincePageLoadStart('Page load', 'Gallery')
    }
  }, [posts])

  const itemParams = {
    onLoad: () => setLoadedImages(loadedImages + 1)
  }

  return (
    <div className='PostList' data-testid='postlist'>
      <Header />
      <Page className='PostList__Main'>
        {posts.length > 0
          ? (
            <>
              <EmailGate />
              <GalleryGrid
                className='track-gallery'
                Component={PostItem}
                items={posts}
                itemParams={itemParams}
                windowSize={WINDOW_SIZE}
              />
            </>
            )
          : (
            <RegisterFan scheduleItemId={parseInt(scheduleItemId)} />
            )}
      </Page>
    </div>
  )
}
