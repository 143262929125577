import React, { useState } from 'react'
import { eventSearch } from 'api'
import Field from 'components/core/Field'
import Loading from 'icons/loading.svg'
import Logo from 'icons/logo.min.white.svg'
import './styles.scss'

interface SearchResult {
  name: string
  locator: string
  description: string
}

export default function EventSearchPage (): JSX.Element {
  const [loaded, setLoaded] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [query, setQuery] = useState<string>('')
  const [results, setResults] = useState<any[]>([])

  const onChangeQuery = ({
    currentTarget: { value }
  }: React.FormEvent<HTMLInputElement>): void => {
    setQuery(value)

    if (value !== '') {
      setLoading(true)
      void performSearch(value)
    } else {
      setLoading(false)
      setResults([])
    }
  }

  const performSearch = async (q): Promise<void> => {
    try {
      const json = await eventSearch(q)

      setResults(json.events)
      setLoading(false)
      setLoaded(true)
    } catch {
      setLoading(false)
    }
  }

  const showResults = query !== '' && !loading

  return (
    <div className='EventSearch' data-testid='EventSearchPage'>
      <div className='EventSearch__Body'>
        <Logo className='EventSearch__Logo' />
        <div className='EventSearch__Form'>
          <h1 className='EventSearch__Title'>Find your photo</h1>
          <div className='EventSearch__QuerySection'>
            <Field
              className='EventSearch__Query'
              value={query}
              onChange={onChangeQuery}
              placeholder='Start typing your event name...'
              autoFocus
              autoComplete='off'
              spellCheck={false}
            />
            {loading && (
              <span data-testid='loading-image'>
                <Loading className='EventSearch__Loading' />
              </span>
            )}
          </div>
          <hr />
          {showResults && (
            <div data-testid='search-results' className='EventSearch__Results'>
              {results.map((e: SearchResult) => (
                <div className='EventSearch__Result' key={e.locator}>
                  <a href={`/${e.locator}`}>{e.name}</a>
                </div>
              ))}
            </div>
          )}
          {loaded && (
            <div className='EventSearch__Footer'>
              <p>
                <strong>Don&apos;t see your event?</strong>
              </p>
              <p>
                Shoot us a note at{' '}
                <a href='mailto:support@stagepilot.com'>
                  support@stagepilot.com
                </a>
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
