import React, { ReactNode, useContext, useEffect } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { thumbPath, downloadPath, optionsForPost, ImageSize } from 'api'
import { AppContext, IAppContext } from 'context'
import { track } from 'libs/analytics'
import Modal from 'components/core/Modal'
import Image from 'components/core/Image'
import Side from 'components/core/Side'
import Preload from 'components/core/Preload'
import EmailGate from 'components/EmailGate'
import Next from './next.svg'
import Previous from './previous.svg'
import { IPost } from 'models'
import { trackEngagementEvent } from 'components/EngagementTracker'
import { trackTimeSincePageLoadStart } from 'components/PerformanceTracker'
import './style.scss'

export default function PostModal (): ReactNode {
  const navigate = useNavigate()
  const { event = {}, posts = [], loadEvent } = useContext<IAppContext>(AppContext)
  const { locator = '', scheduleItemId = '', postId } = useParams()

  const index = posts.findIndex((p) => p.id === parseInt(postId ?? '0')) ?? -1
  const post = posts[index]
  const prevPost = posts[index - 1]
  const nextPost = posts[index + 1]

  useEffect((): (() => void) => {
    document.addEventListener('keydown', handleKeyboardEvent, false)
    return () => document.removeEventListener('keydown', handleKeyboardEvent, false)
  }, [prevPost, nextPost, postId])

  useEffect((): void => {
    if (locator !== '' && locator !== event?.locator) void loadEvent(locator)
  }, [locator])

  useEffect((): void => {
    if (post !== undefined) trackView(post)
  }, [post])

  const postLink = ({ id }: IPost): string => `/${locator}/${scheduleItemId}/${id}`

  const handleKeyboardEvent = (event: KeyboardEvent): void => {
    if (event.target !== document.body) return

    // Left
    if (event.code === 'ArrowLeft' && (prevPost !== undefined)) navigate(postLink(prevPost))

    // Right
    if (event.code === 'ArrowRight' && (nextPost !== undefined)) navigate(postLink(nextPost))
  }

  const trackView = (post: IPost): void => {
    track('Viewed Post', {
      event: locator,
      postId,
      digest: post.contentDigest
    })
  }

  const trackDownload = (): void => {
    trackEngagementEvent('Download', {
      scheduleItemId,
      postId
    })
  }

  const closeModal = (): void => navigate(`/${locator}/${scheduleItemId}`)
  const measureLoadTime = (): void => trackTimeSincePageLoadStart('Page load', 'Post')
  const formattedImagePath = (post: IPost): string => thumbPath(post.contentDigest, ImageSize.large, optionsForPost(post))
  const thumbImagePath = (post: IPost): string => thumbPath(post.contentDigest, ImageSize.thumb, optionsForPost(post))

  return (
    <div className='PostModal' data-testid='PostModal'>
      <EmailGate />
      <Modal show handleClose={closeModal} fullScreen>
        {(post != null) && (
          <div className='Page'>
            <Side focus className='PostModal__Body'>
              <Image
                className='track-post-image PostModal__Image'
                src={formattedImagePath(post)}
                previewSrc={thumbImagePath(post)}
                alt={`Photo ${post.id}`}
                onLoad={measureLoadTime}
              />
              {(prevPost !== undefined) && (
                <LinkWithoutFocus
                  className='PostModal__NavLink PostModal__NavLink--left'
                  to={postLink(prevPost)}
                  role='navLeft'
                >
                  <Previous />
                </LinkWithoutFocus>
              )}
              {(nextPost !== undefined) && (
                <LinkWithoutFocus
                  className='PostModal__NavLink PostModal__NavLink--right'
                  to={postLink(nextPost)}
                  role='navRight'
                >
                  <Next />
                </LinkWithoutFocus>
              )}
              <PreloadImageBlock posts={[nextPost, prevPost]} />
              <a
                className='PostModal__Download '
                role='button'
                download
                href={downloadPath(post.contentDigest ?? '')}
                onClick={trackDownload}
              >
                Download
              </a>
            </Side>
          </div>
        )}
      </Modal>
    </div>
  )
  // }
}

interface PreloadImageBlockProps {
  posts: Array<IPost | undefined>
}

const PreloadImageBlock: React.FC<PreloadImageBlockProps> = ({ posts }) => (
  <>
    {[ImageSize.thumb, ImageSize.large].map((size) =>
      posts.map(
        (post) =>
          (post != null) && (
            <Preload
              href={thumbPath(post.contentDigest, size)}
              key={post.contentDigest}
            />
          )
      )
    )}
  </>
)

interface LinkWithoutFocusProps {
  className: string
  to: string
  role: string
  children: React.ReactNode
}

const LinkWithoutFocus: React.FC<LinkWithoutFocusProps> = ({
  className,
  to,
  role,
  children
}) => (
  <Link className={className} to={to} role={role} onMouseDown={(e) => e.preventDefault()}>
    {children}
  </Link>
)
