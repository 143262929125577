import React, { FunctionComponent, MouseEventHandler, useEffect } from 'react'
import classNames from 'classnames'

import Icon from 'icons/close.min.svg'

import './style.scss'

interface ModalProps {
  handleClose: MouseEventHandler
  show: boolean
  fullScreen?: boolean
  children: React.ReactNode
}

const Modal: FunctionComponent<ModalProps> = ({
  handleClose,
  show,
  fullScreen = false,
  children
}) => {
  useEffect(() => {
    if (fullScreen) {
      document.body.classList.add('no-scroll')

      // Clean up function
      return function cleanup () {
        document.body.classList.remove('no-scroll')
      }
    }
  })

  const className = classNames('Modal', {
    'Modal--shown': show,
    'Modal--centered': !fullScreen,
    'Modal--fullScreen': fullScreen
  })

  return (
    <div className={className}>
      <div className='Modal__Header'>
        <button className='Modal__Close' onClick={handleClose}>
          <Icon />
        </button>
      </div>

      <div className='Modal__Content'>{children}</div>
    </div>
  )
}

export default Modal
