import { IEvent, IUser, IPost, IScheduleItem } from 'models'
import * as globals from '../globals'
export * from './images'

// Posts

export const getPosts = async (locator: string, scheduleItemId: number): Promise<IPost[]> => {
  const json = await getApi(`/events/${locator}/${scheduleItemId}/images`)
  return json.posts
}

// Schedule Items

type GetPublishedScheduleItemsResult = Promise<{
  event: IEvent
  scheduleItems: IScheduleItem[]
}>
export const getPublishedScheduleItems = async (locator: string): GetPublishedScheduleItemsResult => {
  return await getApi(`/events/${locator}/published`)
}

// Fans

interface IRegisterFanData extends IUser {
  scheduleItemId: number
  waitingForPhotos: boolean
}

interface IRegisterFanInput {
  locator: string
  data: IRegisterFanData
}

export const registerFan = async ({ locator = '', data }: IRegisterFanInput): Promise<any> => {
  if (locator === '') throw new Error('Trying to register fan, but the event locator is not provided.')
  return await postApi(`/events/${locator}/register`, data)
}

export const checkPassword = async ({ locator, scheduleItemId, password }: { locator: string, scheduleItemId: number, password: string }): Promise<any> => {
  return await putApi(`/events/${locator}/${scheduleItemId}/password`, { password })
}

// Events

export const eventSearch = async (query: string): Promise<any> => {
  return await getApi(`/search/events?q=${query}`)
}

// Location

export const getLocation = async (): Promise<string> => {
  return (await getApi('/location')).countryCode
}

// Tracking

export const trackDownload = async (locator: string, attributes: any): Promise<any> => {
  return await postApi(`/events/${locator}/actions/download`, attributes)
}

// helpers

const getApi = async (path: string, params: any = undefined): Promise<any> => {
  return await callApi('GET', path, params)
}

const postApi = async (path: string, params: any): Promise<any> => {
  return await callApi('POST', path, params)
}

const putApi = async (path: string, params: any): Promise<any> => {
  return await callApi('PUT', path, params)
}

export const formatDate = (dateStr: string, options: any = {}): string => {
  const formatter = new Intl.DateTimeFormat([], {
    timeZone: 'UTC',
    ...options
  })

  return formatter.format(new Date(dateStr))
}

const getErrorMessage = (textBody: string): string => {
  try {
    const reason: string = JSON.parse(textBody)?.reason ?? ''
    if (reason !== '') return reason
  } catch (err) {
    // Error response wasn't valid JSON, but that's fine
  }
  return textBody
}

const callApi = async (
  method: string,
  path: string,
  params: any
): Promise<any> => {
  const headers = {
    'Content-Type': 'application/json',
    'Client-Rev': globals.gitRev
  }

  const fetchParams: RequestInit = {
    headers,
    method
  }

  if (params !== undefined) {
    fetchParams.body = JSON.stringify(params)
  }

  try {
    const resp = await fetch(`${globals.apiHost}/api${path}`, fetchParams)

    if (!resp.ok) {
      const textBody = await resp.text()

      const error = new APIErrorResponse(
        method,
        path,
        resp.status,
        getErrorMessage(textBody)
      )
      throw error
    }

    if (resp.headers.get('Content-Type') === 'application/json') {
      return await resp.json()
    }
  } catch (error: any) {
    // Add details that will be reported to rollbar
    error.details = {
      method,
      path,
      ...error.details
    }

    throw error
  }
}

export class APIErrorResponse extends Error {
  details: any

  constructor (method, path, status, message) {
    super('API responded with an error')
    this.name = 'APIErrorResponse'
    this.details = {
      method,
      path,
      status,
      message
    }
  }
}
