import React from 'react'
import classNames from 'classnames'
import './style.scss'

// SquareContainer is a container that will retain a square shape, no matter
// what size it becomes.
export default function SquareContainer ({
  className = '',
  children
}): JSX.Element {
  const classnames = classNames('SquareContainer', className)

  return (
    <div className={classnames}>
      <div className='SquareContainer__Contents'>{children}</div>
    </div>
  )
}
