import * as globals from '../globals'

export interface ISize {
  size: number
  fit?: boolean
  rotated?: boolean
  x?: number
  y?: number
  ratio?: number
  width?: number
  height?: number
}
export interface IOpts {
  size?: number
  height?: number
  width?: number
  fit?: boolean
  format?: string
  animated?: boolean
  crop?: ISize
  rotation?: number
  unrotated?: boolean
  quality?: number
}

export const ImageSize: Record<string, ISize> = {
  thumb: { size: 480, fit: true },
  large: { size: 1280, fit: true }
}

export const optionsForPost = (post, opts?: IOpts): any => {
  const options: IOpts = {}

  if (post.isAnimated !== undefined) {
    options.format = 'gif'
    options.animated = true
  }

  return { ...options, ...opts }
}

const getThumbRatio = (thumbWidth: number, cropWidth: number): number => {
  const ratio = thumbWidth / cropWidth
  return ratio > 0 ? ratio : 1
}

export const imageForItem = (item: any): string => {
  const { crop, post: { contentDigest: digest } } = item

  return imagePath({
    digest,
    opts: {
      crop,
      // category: 0,
      width: Math.round(crop.width * crop.ratio),
      height: Math.round(crop.height * crop.ratio),
      fit: true,
      quality: 100
    }
  })
}

const getRotation = (rotation: number | undefined, hasCrop: boolean, rotated: boolean): number => {
  if (!(hasCrop && rotated)) return rotation ?? 0
  if (rotation !== undefined) return rotation
  return 1
}

const getParamValue = (name: string, value: number | string | undefined, check: boolean = false): string | undefined => {
  if (check && value !== undefined) return `${name}=${value}`
}

interface IimagePathParams {
  digest: string | undefined
  opts: IOpts
}

export const imagePath = ({ digest = '', opts = {} }: IimagePathParams): string => {
  const { size, width, height, fit = false, crop, rotation, unrotated = false, format, animated = false, quality = 0 } = opts
  const { x = 0, y = 0, ratio = 0, width: cropWidth = 0, height: cropHeight = 0, rotated = false } = crop ?? {}

  const hasCrop = crop !== undefined
  const newRotation = getRotation(rotation, hasCrop, rotated)

  const thumbRatio = getThumbRatio(width ?? size ?? 500, Math.round(cropWidth * ratio))

  const cropX = Math.round(-x * ratio)
  const paddingHorizontal = Math.round(Math.abs(cropX) * thumbRatio)
  const hasNegCropX = cropX < 0

  const cropY = Math.round(-y * ratio)
  const paddingVertical = Math.round(Math.abs(cropY) * thumbRatio)
  const hasNegCropY = cropY < 0

  const params: Array<string | undefined> = []

  params.push(getParamValue('w', size ?? width, true))
  params.push(getParamValue('h', size ?? height, true))
  params.push(getParamValue('m', 'fit', fit))
  params.push(getParamValue('cx', Math.max(cropX, 0), hasCrop))
  params.push(getParamValue('cy', Math.max(cropY, 0), hasCrop))
  params.push(getParamValue('cw', Math.round(cropWidth * ratio), hasCrop))
  params.push(getParamValue('ch', Math.round(cropHeight * ratio), hasCrop))
  params.push(getParamValue('pl', paddingHorizontal, hasNegCropX))
  params.push(getParamValue('pr', paddingHorizontal, hasNegCropX))
  params.push(getParamValue('pt', paddingVertical, hasNegCropY))
  params.push(getParamValue('pb', paddingVertical, hasNegCropY))
  params.push(getParamValue('r', newRotation, newRotation > 0 && !unrotated))
  params.push(getParamValue('format', format, true))
  params.push(getParamValue('animated', 'true', animated))
  params.push(getParamValue('q', quality, quality > 0))

  const stringParams = params.filter(i => i !== undefined && i !== '' && i !== '=').join('&')

  return `${globals.imageHost}/resized/${digest}?${stringParams}`
}

export const thumbPath = (digest: string | undefined, size: ISize, opts?: IOpts): string => {
  size = size ?? ImageSize.thumb
  opts = { ...size, ...opts }

  return imagePath({ digest, opts })
}

export const downloadPath = (digest: string): string =>
  `${globals.imageHost}/originals/${digest}?response-content-disposition=attachment`
