import { useEffect } from 'react'
import { gaUserTiming } from 'libs/analytics'

export const PAGE_LOAD_START_MARK = 'page load start'

let hasPerformanceAPI = false

export default function PerformanceTracker (): null {
  useEffect(() => {
    try {
      performance.mark(PAGE_LOAD_START_MARK)
      hasPerformanceAPI = true
    } catch {}
  }, [location])

  return null
}

export const trackTimeSincePageLoadStart = (
  category: string,
  measureName: string
): void => {
  if (!hasPerformanceAPI) return

  const ms = timeSincePageLoadStart(measureName)
  gaUserTiming(category, measureName, ms)
}

const timeSincePageLoadStart = (measureName: string): number => {
  const startPageLoadMarks = window.performance.getEntriesByName(PAGE_LOAD_START_MARK)

  // if we stored a PAGE_LOAD_START_MARK mark, use that as origin
  if (startPageLoadMarks.length > 0) {
    performance.measure(measureName, PAGE_LOAD_START_MARK)
  } else {
    // otherwise use the navigation start
    performance.measure(measureName)
  }

  const measures = window.performance.getEntriesByName(measureName)
  const measure = measures[measures.length - 1]

  return Math.round(measure.duration)
}
