import React, { PropsWithChildren } from 'react'
import classNames from 'classnames'
import './style.scss'

interface Props {
  focus?: boolean
  small?: boolean
  hidden?: boolean
  className?: string
}

const Side = ({
  focus = false,
  small = false,
  hidden = false,
  className,
  children
}: PropsWithChildren<Props>): JSX.Element => {
  const classnames = classNames('Side', className, {
    'Side--focus': focus,
    'Side--small': small,
    'Side--hidden': hidden
  })

  return <div className={classnames}>{children}</div>
}

export default Side
