import React, { MouseEventHandler, RefObject, useContext, useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import classNames from 'classnames'
import { closestIndexTo, endOfToday } from 'date-fns'
import { AppContext, IAppContext, ModalContext, IModalContext } from 'context'
import { IScheduleItem } from 'models'
import { loadPassword } from 'localStorage'
import Header from 'components/Header'
import PasswordGate from 'components/PasswordGate'
import { isUserRegistered } from 'libs/helpers'
import './style.scss'
import Debug from 'debug'
import { formatDate } from 'api'

const log = Debug('gallery').extend('ScheduleItemList')

interface Props {
  locator: string
  item: IScheduleItem
  domRef: RefObject<HTMLDivElement> | null
  closest: boolean
}

const formatForList = (startsAt: string): string => {
  return formatDate(startsAt, {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    weekday: 'short'
  })
}

const ScheduleItem: React.FC<Props> = ({ locator, item, domRef, closest }) => {
  const { showPasswordModal } = useContext<Partial<IModalContext>>(ModalContext)
  const { event, user } = useContext<IAppContext>(AppContext)
  const { location, description, startsAt, hasPassword = false, time, id } = item

  const to = `/${locator}/${id}`

  const onClick: MouseEventHandler = (e) => {
    if (hasPassword && loadPassword(id) === '') {
      showPasswordModal?.(true, { to, event, id })
      e.preventDefault()
    }
  }

  let state = 'register'
  let action = 'Notify me'

  const { postCount = 0 } = item

  if (postCount > 0) {
    state = 'visit'
    action = 'See photos'
  } else if (isUserRegistered(user, id)) {
    state = 'registered'
    action = 'Registered'
  }

  const className = classNames(
    'ScheduleItemListItem',
    `ScheduleItemListItem--${state}`,
    { 'ScheduleItemListItem--closest': closest }
  )

  return (
    <Link className={className} to={`/${locator}/${id}`} onClick={onClick}>
      <div ref={domRef} className='ScheduleItemListItem__Info'>
        <div className='ScheduleItemListItem__Date'>
          {formatForList(startsAt)}
          <span className='ScheduleItemListItem__Time'>{time}</span>
        </div>
        <div className='ScheduleItemListItem__Text'>
          <div className='ScheduleItemListItem__Location'>{location}</div>
          <div className='ScheduleItemListItem__Description'>{description}</div>
        </div>
      </div>

      <button
        className='ScheduleItemListItem__Action Button'
        data-testid={`action${item.id}`}
      >
        {action}
      </button>
    </Link>
  )
}

interface IfindClosestParams {
  scheduleItems: IScheduleItem[] | undefined
  currentElement: HTMLDivElement | null
}

const findClosestId = ({ scheduleItems = [], currentElement = null }: IfindClosestParams): number | undefined => {
  const today = endOfToday()
  const dates = scheduleItems.map(({ startsAt }) => new Date(startsAt))

  const closestIndex = closestIndexTo(today, dates) ?? 0
  const closest = scheduleItems[closestIndex]

  log({ func: 'findClosestId', closest, scrollY: window.scrollY, scheduleItems, currentElement })

  return closest?.id
}

export default function ScheduleItemList (): JSX.Element {
  const { event, loadEvent } = useContext<IAppContext>(AppContext)
  const [closestId, setClosestId] = useState<number | null>()
  const closestRef: RefObject<HTMLDivElement> = React.createRef()
  const { locator = '' } = useParams()
  const { scheduleItems } = event

  useEffect(() => { if (locator !== event.locator) void loadEvent(locator) }, [locator])
  useEffect(() => {
    const newClosestId = findClosestId({ scheduleItems, currentElement: closestRef?.current })
    setClosestId(newClosestId)
  }, [setClosestId, scheduleItems, closestRef])

  const setClosestRef = (closest: boolean): React.RefObject<HTMLDivElement> | null => (closest ? closestRef : null)

  return (
    <div className='ScheduleItemList'>
      <Header />
      <div className='ScheduleItemList__Main'>
        {event.scheduleItems?.map((item) => (
          <ScheduleItem
            key={item.id}
            locator={event.locator ?? ''}
            domRef={setClosestRef(item.id === closestId)}
            item={item}
            closest={item.id === closestId}
          />
        ))}
      </div>
      <PasswordGate />
    </div>
  )
}
