import React, { DragEventHandler, ReactNode, useState } from 'react'
import classNames from 'classnames'
import './style.scss'

interface DropTargetProps {
  className?: string
  uploadFile: (File) => void
  children: ReactNode
}

export default function DropTarget ({
  className,
  uploadFile,
  children
}: Readonly<DropTargetProps>): ReactNode {
  const [hover, setHover] = useState<boolean>(false)
  const el = React.createRef<HTMLDivElement>()

  const onDragEnter = (e: React.DragEvent<HTMLDivElement>): void => {
    if (containsFiles(e)) {
      e.dataTransfer.dropEffect = 'copy'
      setHover(true)
    }
  }

  const onDragLeave: DragEventHandler = (e): void => {
    if (e.target === el.current) {
      setHover(false)
    }
  }

  const onDragOver: DragEventHandler = (e) => {
    e.stopPropagation()
    e.preventDefault()
  }

  const onDrop: DragEventHandler = (e) => {
    e.preventDefault()
    setHover(false)

    if (containsFiles(e)) {
      for (const element of e.dataTransfer.files) {
        uploadFile(element)
      }
    }
  }

  const containsFiles = (e: React.DragEvent): boolean => {
    const types = Array.from(e.dataTransfer?.types ?? [])
    return types.includes('Files')
  }

  const classes = classNames(className, 'DropTarget', {
    'DropTarget--hover': hover
  })

  return (
    <div
      ref={el}
      className={classes}
      onDragEnter={onDragEnter}
      onDragOver={onDragOver}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
    >
      {children}
      {hover && (
        <div className='DropTarget__DropArea'>
          <div className='DropTarget__Caption'>
            <span>Drop here to upload</span>
          </div>
        </div>
      )}
    </div>
  )
}
