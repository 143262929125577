import React, { FormEvent, ChangeEventHandler, useContext, useState, ReactNode } from 'react'
import Field from 'components/core/Field'
import DropTarget from 'components/core/DropTarget'
import MarketingCheckbox from 'components/core/MarketingCheckbox'
import classNames from 'classnames'
import { AppContext, IAppContext } from 'context'
import RockNRoll from 'icons/rocknroll.png'
import { IUser } from 'models'
import { registerFan } from 'api'
import './style.scss'
import { isUserRegistered } from 'libs/helpers'
import { useParams } from 'react-router-dom'

export function RegisterFanForm (): ReactNode {
  const { user = {}, event, updateUser } = useContext<IAppContext>(AppContext)
  const { locator = '', scheduleItemId = '0' } = useParams()
  const [state, setState] = useState<Partial<IUser>>(user ?? {})
  const [fanImage, setFanImage] = useState<any>()

  const {
    faceDetectionEnabled = false,
    termsOfServiceUrl = 'https://stagepilot.com/legal/terms-of-use',
    privacyPolicyUrl = 'https://stagepilot.com/legal/privacy'
  } = event

  const handleSubmit = async (e: FormEvent): void => {
    e.preventDefault()
    const sid = parseInt(scheduleItemId)
    const items: number[] = user?.items ?? []
    items.push(sid)
    const values = { ...user, ...state, items }
    updateUser(values)

    await registerFan({
      locator,
      data: {
        ...values,
        scheduleItemId: sid,
        waitingForPhotos: true
      }
    })
  }

  const handleChange: ChangeEventHandler = ({ target: { name, value } }: React.ChangeEvent<HTMLInputElement>): void => setState({ ...state, [name]: value })

  const handleUpload = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>): void => loadFile(files?.[0])

  const loadFile = (file: Blob | undefined): void => {
    if (file == null) return
    const reader = new FileReader()

    reader.addEventListener('load', (): void => setFanImage(reader.result))
    reader.readAsDataURL(file)
  }

  let preview: JSX.Element | null = null
  if (fanImage?.length > 0) preview = <img src={fanImage} alt='' />

  const className = classNames('track-register-fan', 'RegisterFan', { 'RegisterFan--selfie': faceDetectionEnabled })

  return (
    <div className={className}>
      <form className='RegisterFanForm' onSubmit={handleSubmit} method='post'>
        <div className='RegisterFanStep'>
          <h1 className='RegisterFanStep__Title'>Step 1</h1>
          <p>
            Tell us who you are and where you&apos;d
            <br className='desktop' /> like us to email your photos.
          </p>

          <div className='RegisterFanForm__Field'>
            <Field
              required
              name='firstName'
              placeholder='First name'
              value={state.firstName}
              onChange={handleChange}
              className='RegisterFanForm__Input'
            />
            <Field
              required
              name='lastName'
              placeholder='Last name'
              value={state.lastName}
              onChange={handleChange}
              className='RegisterFanForm__Input'
            />
          </div>

          <div className='RegisterFanForm__Field'>
            <Field
              type='email'
              required
              name='email'
              className='RegisterFanForm__Input'
              placeholder='Email address'
              pattern='^[^\s@]+@[^\s@]+\.[^\s@]+$'
              value={state.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <hr className='RegisterFan__Divider' />
        {faceDetectionEnabled && (
          <div className='RegisterFanStep'>
            <DropTarget
              className='Input__File Input__Control Input__FileArea'
              uploadFile={loadFile}
            >
              <h1 className='RegisterFanStep__Title'>Step 2</h1>
              <p>
                Let us know what you look like and we&apos;ll email
                <br className='desktop' /> your photos as soon as they&apos;re
                uploaded.
              </p>
              <div
                className={classNames(
                  'Input__File',
                  'RegisterFanStep__Selfie',
                  { 'RegisterFanStep__Selfie--preview': preview }
                )}
              >
                <input
                  data-testid='selfie-input'
                  className='Input__FileControl'
                  type='file'
                  accept='image/*'
                  capture='user'
                  id='selfie'
                  name='selfie'
                  onChange={handleUpload}
                />
                {(preview != null)
                  ? (
                    <div className='Input__FileInputPreview'>
                      <label htmlFor='selfie' className='Button'>
                        &times;
                      </label>
                      {preview}
                    </div>
                    )
                  : (
                    <>
                      <label
                        htmlFor='selfie'
                        className='Button Input__FileButton mobile'
                      >
                        Snap a selfie
                      </label>
                      <label
                        htmlFor='selfie'
                        className='Button Input__FileButton desktop'
                      >
                        Upload a selfie
                      </label>
                    </>
                    )}
              </div>
            </DropTarget>
          </div>
        )}
        <div className='RegisterFanStep'>
          <button className='RegisterFanForm__Submit highlighted' type='submit'>
            Submit!
          </button>

          <div className='RegisterFanForm__Field RegisterFanForm__Field--marketing'>
            <MarketingCheckbox />
          </div>
          <p className='RegisterFanForm__Legal'>
            By continuing, you agree to our
            <br className='mobile' />{' '}
            <a href={termsOfServiceUrl}>Terms of Use</a>
            {' and '}
            <a href={privacyPolicyUrl}>Privacy Policy</a>.
          </p>
        </div>
      </form>
    </div>
  )
  // }
}

const RegisteredFan = (): ReactNode => (
  <div className='track-registered-fan RegisterFan RegisterFan--finished'>
    <div className='RegisterFanStep'>
      <img src={RockNRoll} alt='Registered!' className='RegisterFan__Icon' />
      <h1 className='RegisterFan__Title'>You&apos;re on the list</h1>

      <p>Stay tuned for an email when your photo is uploaded.</p>
    </div>
  </div>
)

export interface RegisterFanProps {
  scheduleItemId: number
}
export default function RegisterFan ({ scheduleItemId }: RegisterFanProps): ReactNode {
  const { user = {} } = useContext<IAppContext>(AppContext)
  const registered = isUserRegistered(user, scheduleItemId)
  return registered ? <RegisteredFan /> : <RegisterFanForm />
}
