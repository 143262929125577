import React, { ReactNode } from 'react'
import ApplicationError from './core/ApplicationError'
import rollbar from 'errorReporting'

interface ErrorBoundaryState {
  error: Error | null
}
interface ErrorBoundaryProps {
  children?: ReactNode
}
class ErrorBoundary extends React.Component<
ErrorBoundaryProps,
ErrorBoundaryState
> {
  state: ErrorBoundaryState = {
    error: null
  }

  static getDerivedStateFromError<T>(error: T): Record<string, T> {
    return { error }
  }

  componentDidCatch (error): void {
    rollbar.error(error)
  }

  render (): ReactNode {
    const { error } = this.state

    if (error != null) {
      return <ApplicationError />
    }

    return this.props.children
  }
}

export default ErrorBoundary
