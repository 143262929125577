import React from 'react'
import classNames from 'classnames'
import './style.scss'

interface Props {
  className?: string
  children: React.ReactNode
}

const Page: React.FunctionComponent<Props> = ({ className, children }) => {
  const classnames = classNames('Page', className)

  return <div className={classnames}>{children}</div>
}

export default Page
