import debug from 'debug'
import { IUser } from 'models'

declare global {
  interface Window {
    ga?: any
    mixpanel?: any
  }
}

const log = debug('analytics')

const MAX_TIMING_VALUE = 5 * 60 * 60 * 1000 // 5 minutes in miliseconds

export const gaUserTiming = (
  timingCategory: string,
  timingVar: string,
  timingValue: number
): void => {
  if (typeof window.ga !== 'function') {
    return
  }

  if (timingValue >= MAX_TIMING_VALUE) {
    return
  }

  window.ga('gtm1.send', {
    hitType: 'timing',
    timingCategory,
    timingVar,
    timingValue
  })
}

export const pageview = (attributes: any): void => {
  window.mixpanel.track('pageview', attributes)
}

export const track = (event: string, attributes: any): void => {
  log('Track event', event, attributes)
  window.mixpanel.track(event, attributes)
}

export const trackUserProperties = (attributes: any): void => {
  window.mixpanel.people.set(attributes)
}

export const associateUser = (user: IUser): void => {
  log('Associating user', user)
  window.mixpanel.identify(user.id)
}
