import { ReactNode, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import * as analytics from 'libs/analytics'

export default function PageviewTracker (): ReactNode {
  const { pathname } = useLocation()
  const [oldPath, setOldPath] = useState<string>(pathname)

  useEffect(() => {
    if (pathname !== oldPath) {
      track()
      setOldPath(pathname)
    }
  }, [pathname])

  function track (): void {
    analytics.pageview({
      page_title: document.title,
      page_location: window.location.href,
      page_path: location.pathname
    })
  }

  return null
}
