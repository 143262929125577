import React from 'react'

const ApplicationError = (): JSX.Element => (
  <div className='ApplicationError' data-testid='application-error'>
    <h1>Something went wrong, sorry about that</h1>
    <p>
      Please refresh this screen or{' '}
      <a href='/'>click here to reload StagePilot</a>.
    </p>
    <p>
      If the problem persists, please contact us at{' '}
      <a href='mailto:support@stagepilot.com'>support@stagepilot.com</a>.
    </p>
  </div>
)

export default ApplicationError
